<template>
  <div class="attention-container">
    <van-cell
      v-for="history in historyList"
      :key="history.id"
      @click="toDetail(history.id)"
      :title="history.name"
      is-link
    />
    <Queries ref="queries" />
    <Attention ref="attention" />
  </div>
</template>

<script>
import { drugHistory } from '@/api/history'
import Queries from '@/components/Queries'
import Attention from '@/components/Attention'

export default {
  components: { Queries, Attention },
  data() {
    return {
      page: {
        page: 1,
        pagesize: 20
      },
      historyList: [],
      userInfo: {}
    }
  },
  methods: {
    // 药品详情
    toDetail(id) {
      if (this.userInfo.view === 0) {
        return this.$refs.queries.queriesShow = true
      }
      this.$router.push({
        path: '/detail',
        query: {
          id
        }
      })
    },
    // 获取历史浏览记录
    async getHistoryList() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      try {
        const { data } = await drugHistory({ user_id: this.userInfo.id, ...this.page })
        this.historyList = data.data.reverse()
      } catch (error) {
        if(error.response.data.msg == '请先关注') this.$refs.attention.attentionShow = true
      }

    }
  },
  created() {
    this.getHistoryList()
  }

}
</script>

<style lang="less" scoped>
</style>